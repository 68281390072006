.css-mcpu91 {
    font-size: var(--chakra-fontSizes-xl);
    background: #ffffff;
    padding: 36px 50px 36px 40px;
    margin: 0px 0px 0px 20px;
    box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
    font-weight:600;
}


.form-label {
    margin-bottom: .5rem;
    font-size: 17px;
}
.applicationTitle {
    text-align: center;
}
